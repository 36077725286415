<template>
    <admin-dashboard-layout>
        <v-card>
            <v-card-title>
                <v-row>
                    <v-col>
                        {{dealership.name}}
                    </v-col>
                    <v-col class="text-right">
                        <v-btn text :to="{name: 'admin.dealerships.index'}">return</v-btn>
                    </v-col>
                </v-row>
            </v-card-title>
            <v-card-text>
                <v-form>
                    <v-row>
                        <v-col>
                            <v-text-field v-model="dealership.name" disabled></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col>
                            <v-text-field v-model="dealership.created_at" disabled></v-text-field>
                        </v-col>
                    </v-row>
                </v-form>
            </v-card-text>
        </v-card>
    </admin-dashboard-layout>
</template>

<script>
    import AdminDashboardLayout from "../../../layouts/AdminDashboardLayout";
    import Dealership from "../../../models/Dealership";

    export default {
        name: "users-show",
        components: {AdminDashboardLayout},
        data: function () {
            return {
                dealership: {}
            }
        },
        async mounted() {
            const dealershipId = this.$route.params.id
            this.dealership = await Dealership.find(dealershipId)
        },
    }
</script>

<style scoped>

</style>
